.about-title {
    width: 100%;
    padding-top: 50px;
    margin: 0 auto 80px auto;
    @media ( max-width: 600px ) {
        padding-top: 120px;
        margin-bottom: 40px;
    }
    &__inner {
        width: 100%;
        max-width: 1440px;
        padding: 0 120px;
        margin: 0 auto;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 600px ) {
            padding: 0 20px;
        }
    }
    &__title {
        @include h1(200);
        margin-bottom: 30px;
        @media ( max-width: 991px ) {
            margin-bottom: 40px;
        }
        strong {
            display: block;
            font-weight: 600;
        }
    }
    &__intro {
        @include h4(600);
        width: 900px;
        max-width: 100%;
        em {
            font-style: normal;
            color: #ED2D26;
        }
        &__icons {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: stretch;
            &__icon {
                width: 50%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 20px;
                &__img {
                    margin-bottom: 15px;
                    @media ( max-width: 991px ) {
                        max-height: 50px;
                        margin-bottom: 5px;
                    }
                }
                &__text {
                    font-family: "opensans", Helvetica, Arial, Sans-Serif;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    color: #37393E;
                    opacity: 0.72;
                    text-align: center;
                    @media ( max-width: 991px ) {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }
        }
        &--two-cols {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            @media (max-width: 1280px) {
                padding: 0 40px;
            }
            @media ( max-width: 767px ) {
                flex-direction: column;
            }
            @media ( max-width: 600px ) {
                padding: 0;
                margin-bottom: 0;
            }
        }
        &__col {
            width: calc((100% - 50px) / 2);
            @media ( max-width: 767px ) {
                width: 100%;
                margin-bottom: 40px;
            }
        }
    }
}
