.text-cta {
    width: 100%;
    margin: 0 auto 130px auto;
    padding-top: 60px;
    @media ( max-width: 991px ) {
        padding-top: 30px;
        margin: 0 auto 60px auto;
    }
    @media ( max-width: 600px ) {
        padding: 30px 20px 0 20px;
    }
    &__inner {
        max-width: 875px;
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }
    &__title {
        @include h2(200, 65px, 4.5vw);
        color: #1C1D1E;
        margin-bottom: 40px;
        @media ( max-width: 1024px ) {
            font-size: 35px;
            line-height: 46px;
        }
        strong {
            font-weight: 600;
        }
    }
    &__text {
        @include h4(600);
        color: #1C1D1E;
        margin-bottom: 80px;
        @media ( max-width: 1024px ) {
            font-size: 21px;
            line-height: 28px;
            margin-bottom: 40px;
        }
        @media ( max-width: 767px ) {
            font-size: 18px;
            line-height: 24px;
        }
        p {
            margin-bottom: 2.75vw;
            @media ( max-width: 1024px ) {
                margin-bottom: 28px;
            }
            @media ( min-width: 1440px ) {
                margin-bottom: 40px;
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .btn {
        min-width: 290px;
        text-align: center;
        @media ( max-width: 600px ) {
            min-width: 0;
        }
        .btn__asset {
            float: right;
            cursor: pointer;
        }
    }
    &--image {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 150px 40px;
        margin: 0 auto 120px auto;
    }
    &--light-bg {
        @media ( max-width: 600px ) {
            padding: 0;
        }
        .text-cta__inner {
            background-color: rgba(255, 255, 255, 0.8);
            padding: 70px 110px;
            max-width: 1000px;
            @media ( max-width: 991px ) {
                padding: 50px;
            }
            @media ( max-width: 600px ) {
                padding: 20px;
            }
        }
    }
    &--light {
        .text-cta__title {
            color: #FFF;
        }
        .text-cta__text {
            color: #FFF;
        }
    }
    &--bg-gray {
        background-color: #F8F8F8;
        .text-cta__text {
            font-weight: 100;
        }
    }
    &--bg-blue {
        margin-bottom: 120px;
        .text-cta__text {
            color: #FFFFFF;
        }
    }
    &--bg-red {
        .text-cta__text {
            color: #FFFFFF;
        }
    }
}
