.medium-title-cta {
    width: 100%;
    padding-top: 232px;
    margin: 0 auto 120px auto;
    @media ( max-width: 768px ) {
        padding-top: 120px;
    }
    @media ( max-width: 600px ) {
        margin-bottom: 60px;
    }
    &__inner {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 120px;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 600px ) {
            padding: 0 20px;
        }
    }
    &__title {
        @include h1(200);
        color: #1C1D1E;
        margin-bottom: 35px;
        @media ( max-width: 1024px ) {
            margin-bottom: 25px;
        }
        strong {
            display: block;
            font-weight: 600;
        }
    }
    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media ( max-width: 1024px ) {
            display: block;
        }
        &__text {
            max-width: calc(100% - 454px - 105px);
            @media ( max-width: 1024px ) {
                max-width: 100%;
            }
            p {
                font-size: 18px;
                line-height: 30px;
                margin-bottom: 30px;
                @media ( max-width: 600px ) {
                    font-size: 14px;
                    line-height: 21px;
                    margin-bottom: 20px;
                }
            }
            &:last-child {
                max-width: 70%;
                margin-bottom: 50px;
                @media ( max-width: 600px ) {
                    max-width: 100%;
                }
                p {
                    margin-bottom: 0;
                }
            }
        }
        &__image {
            width: 454px;
            @media ( max-width: 1024px ) {
                width: 100%;
            }
            &__img {
                max-width: 100%;
            }
        }
    }
}