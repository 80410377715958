/* Mixinis */
@Mixin botones {
   color: white;
   width: 30px;
   border-radius: 10px;
}

@mixin minheight {
   min-height: 100vh;
}

@mixin h1( $weight ) {
   font-size: 70px !important;
   font-size: 5vw !important;
   line-height: 70px !important;
   line-height: 5vw !important;
   font-weight: $weight !important;
   letter-spacing: -1.4px !important;
   @media ( max-width: 1024px ) {
      font-size: 51px !important;
      line-height: 51px !important;
   }
   @media ( max-width: 600px ) {
      font-size: 50px !important;
      line-height: 50px !important;
   }
   @media ( min-width: 1440px ) {
      font-size: 70px !important;
      line-height: 70px !important;
   }
}

@mixin h2( $weight, $linePx: 60px, $lineVw: 4.17vw ) {
   font-size: 50px !important;
   font-size: 3.5vw !important;
   line-height: $linePx !important;
   line-height: $lineVw !important;
   font-weight: $weight  !important;
   letter-spacing: -1px !important;
   @media ( max-width: 1024px ) {
       font-size: 40px !important;
       line-height: 42px !important;
   }
   @media ( max-width: 600px ) {
       font-size: 30px !important;
       line-height: 35px !important;
   }
   @media ( min-width: 1440px ) {
      font-size: 50px !important;
      line-height: $linePx !important;
   }
}

@mixin h3( $weight ) {
   font-size: 40px !important;
   font-size: 2.75vw !important;
   line-height: 60px !important;
   line-height: 4.17vw !important;
   font-weight: $weight !important;
   letter-spacing: -0.8px !important;
   @media ( max-width: 1024px ) {
      font-size: 28px !important;
      line-height: 42px !important;
   }
   @media ( max-width: 600px ) {
      font-size: 22px !important;
      line-height: 30px !important;
   }
   @media ( min-width: 1440px ) {
      font-size: 40px !important;
      line-height: 60px !important;
   }
}

@mixin h4( $weight ) {
   font-size: 30px !important;
   font-size: 2.08vw !important;
   line-height: 40px !important;
   line-height: 2.75vw !important;
   font-weight: $weight !important;
   @media ( max-width: 1024px ) {
       font-size: 21px !important;
       line-height: 28px !important;
   }
   @media ( max-width: 767px ) {
       font-size: 18px !important;
       line-height: 24px !important;
   }
   @media ( min-width: 1440px ) {
      font-size: 30px !important;
      line-height: 40px !important;
   }
}
