.btn {
  padding: 25px 53px !important;
  height: 80px !important;
  border-radius: 0 !important;
  border: none  !important;
  font-family: "opensans", Helvetica, Arial, Sans-Serif !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  font-size: 1.39vw !important;
  line-height: 30px !important;
  transition: all 0.3s !important;
  @media ( max-width: 1280px ) {
    padding: 20px 30px !important;
    height: 60px !important;
    line-height: 20px !important;
  }
  @media ( max-width: 1024px ) {
    font-size: 14px !important;
  }
  @media ( min-width: 1440px ) {
    font-size: 20px !important;
    line-height: 30px !important;
  }
  &__asset {
    display: inline-block !important;
    margin-left: 15px !important;
    position: relative !important;
    right: 0 !important;
    transition: all 0.3s !important;
    @media ( max-width: 1280px ) {
      max-height: 20px !important;
    }
    &--hover {
      display: none !important;
      transition: right 0.3s !important;
      transition-delay: 0.1s !important;
    }
    cursor: pointer;
  }
  &--red {
    background-color: #EC0000 !important;
    color: #FFF !important;
    &:hover {
      background-color: #EC0000 !important;
      background-color: darken($color: #EC0000, $amount: 10) !important;
      color: #FFF;
      cursor: pointer;
    }
  }
  &--blue {
    background-color: #001D6C !important;
    color: #FFF !important;
    &:hover {
      background-color: #001D6C !important;
      background-color: darken($color: #001D6C, $amount: 10) !important;
      color: #FFF;
    }
  }
  &--white {
    background-color: #FFF !important;
    color: #37393E !important;
  }
  &--grey {
    background-color: #E6E9F0 !important;
    color: #37393E !important;
    .btn__asset--hover {
      display: none !important;
      transition-delay: 0.1s !important;
      position: relative !important;
      right: 0 !important;
    }
    &:hover {
      background-color: #ED2D26 !important;
      color: #FFF !important;
      .btn__asset {
        display: none !important;
      }
      .btn__asset--hover {
        display: inline-block !important;
        right: -10px !important;
      }
    }
  }
  &--grey-negative {
    background-color: #FFF !important;
    color: #37393E !important;
    border: 4px solid #37393E !important;
    border: 4px solid rgba(55, 57, 62, 0.06) !important;
    .btn__asset--hover {
      display: none !important;
      position: relative !important;
      right: 0 !important;
      transition-delay: 0.1s !important;
    }
    &:hover {
      background-color: #FFF !important;
      color: #ED2D26 !important;
      border: 4px solid #ED2D26 !important;
      .btn__asset {
        display: none !important;
      }
      .btn__asset--hover {
        display: inline-block !important;
        right: -10px !important;
      }
    }
  }
  &--light {
    padding-left: 20px !important;
    padding-right: 20px !important;
    background-color: transparent !important;
    border: 4px solid #FFF !important;
    border: 4px solid rgba(255, 255, 255, 0.22) !important;
    line-height: 26px !important;
    color: #FFF !important;
    @media ( max-width: 1280px ) {
      line-height: 13px !important;
    }
    &:hover {
      color: #FFF !important;
      border-color: #FFF !important;
    }
  }
  &:hover {
    .btn__asset {
      right: -10px !important;
      cursor: pointer;
    }
  }
}


.link {
  display: inline-block !important;
  padding-top: 13px !important;
  position: relative !important;
  font-family: "opensans", Helvetica, Arial, Sans-Serif !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  font-size: 1.5vw !important;
  line-height: 30px !important;
  line-height: 2.08vw !important;
  color: #ED2D26 !important;
  transition: color 0.3s !important;
  text-decoration: none !important;
  @media ( max-width: 1024px ) {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  @media ( min-width: 1440px ) {
    font-size: 22px !important;
    line-height: 30px !important;
  }
  &__text {
    position: relative !important;
    z-index: 2 !important;
  }
  &:after {
    content: '' !important;
    display: block !important;
    position: absolute !important;
    left: 0 !important;
    right: 0 !important;
    bottom: -3px !important;
    height: 2px !important;
    background-color: #E7E7E7 !important;
    transition: height 0.5s, width 0.5s !important;
  }
  &:hover {
    color: #FFF !important;
    text-decoration: none !important;
    &:after {
      right: -5px !important;
      left: -5px !important;
      height: 33px !important;
      background-color: #ED2D26 !important;
    }
  }
}

button.gm-ui-hover-effect {
  top: -2px !important;
  right: -2px !important;
}
// .gm-style-iw button {display: none !important; }
