.legal {
    padding: 104px 0;
    @media ( max-width: 600px ) {
        padding: 90px 0 40px 0;
    }
    &__heading {
        width: 100%;
        background-color: #37393E;
        margin: 0 auto 95px auto;
        @media ( max-width: 991px ) {
            margin-bottom: 60px;
        }
        @media ( max-width: 600px ) {
            
        }
        &__inner {
            width: 100%;
            max-width: 1440px;
            padding: 0 120px;
            height: 200px;
            margin: 0 auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 1280px) {
                padding: 0 40px;
            }
            @media ( max-width: 600px ) {
                height: 150px;
                padding: 0 20px;
            }
        }
        &__title {
            padding-left: 45px;
            margin: 0;
            position: relative;
            @include h4(600);
            color: #FFF;
            &:after {
                content: '';
                display: block;
                width: 13px;
                height: 13px;
                background-color: #ED2D26;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 2px;
                margin: auto;
            }
        }
    }
    &__content {
        width: 100%;
        max-width: 1440px;
        padding: 0 120px;
        margin: 0 auto;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 600px ) {
            padding: 0 20px;
        }
        &__title {
            @include h4(400);
            color: #1C1D1E;
            margin-bottom: 2.75vw;
            @media ( max-width: 1024px ) {
                margin-bottom: 30px;
            }
            @media ( max-width: 767px ) {
                margin-bottom: 24px;
            }
            @media ( min-width: 1440px ) {
                margin-bottom: 40px;
            }
        }
        &__text {
            font-size: 18px;
            line-height: 30px;
            color: #37393E;
            margin-bottom: 30px;
            @media ( max-width: 600px ) {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 20px;
            }
            strong {
                font-weight: 700;
            }
            .link {
                font-size: inherit;
                line-height: inherit;
                padding-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            & + .legal__content__title {
                padding-top: 75px;
                @media ( max-width: 600px ) {
                    padding-top: 40px;
                }
            }
        }
    }
}

.report {
    width: 100%;
    max-width: 900px;
    height: 221px;
    padding: 70px 75px;
    margin: 0 auto 40px auto;
    border: 1px solid #e7e7e7;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media ( max-width: 991px ) {
        padding: 40px;
    }
    @media ( max-width: 600px ) {
        padding: 20px;
        height: auto;
        display: block;
    }
    &__content {
        @media ( max-width: 600px ) {
            margin-bottom: 20px;
        }
        &__title {
            font-family: "opensans", Helvetica, Arial, Sans-Serif;
            font-weight: 600;
            font-size: 22px;
            line-height: 40px;
            color: #1C1D1E;
            @media ( max-width: 1024px ) {
                font-size: 21px;
                line-height: 28px;
            }
            @media ( max-width: 767px ) {
                font-size: 18px;
                line-height: 24px;
            }
        }
        &__ref {
            font-size: 18px;
            line-height: 30px;
            color: #737477;
            @media ( max-width: 1024px ) {
                font-size: 21px;
                line-height: 28px;
            }
            @media ( max-width: 767px ) {
                font-size: 18px;
                line-height: 24px;
            }
        }
    }
    &__icon {
        display: block;
        text-decoration: none;
        text-align: center;
        @media ( max-width: 600px ) {
            display: block;
            text-align: left;
        }
        &__img {
            @media ( max-width: 600px ) {
                margin-right: 20px;
            }
        }
        &__text {
            display: block;
            color: #737477;
            font-size: 18px;
            line-height: 30px;
            text-decoration: underline;
            transition: all 0.3s;
            @media ( max-width: 600px ) {
                display: inline-block;
            }
            &:hover {
                color: #ED2D26;
            }
        }
    }
    &:empty {
        display: none;
    }
}