.medium-title {
    width: 100%;
    padding-top: 232px;
    margin: 0 auto 80px auto;
    @media ( max-width: 600px ) {
        padding-top: 120px;
        margin-bottom: 40px;
    }
    &__inner {
        width: 100%;
        max-width: 1440px;
        padding: 0 120px;
        margin: 0 auto;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 600px ) {
            padding: 0 20px;
        }
    }
    &__title {
        @include h1(600);
        color: #1C1D1E;
    }
}