.news {
    padding-top: 104px;
}
.new-featured {
    width: 100%;
    margin: 0 auto 60px auto;
    background-image: url("../images/bg-header-title-v2.jpg");
    background-repeat: repeat-x;
    background-position: top center;
    background-size: 100% 420px;
    padding-top: 120px;
    @media ( max-width: 600px ) {
        background-size: 100% 250px;
        padding-top: 60px;
    }
    &__inner {
        width: 100%;
        max-width: 1440px;
        padding: 0 120px;
        margin: 0 auto;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 600px ) {
            padding: 0 20px;
            margin-bottom: 0;
        }
    }
    &__pagetitle {
        width: 100%;
        max-width: 1000px;
        margin: 0 auto 60px auto;
        font-family: "opensans", Helvetica, Arial, Sans-Serif;
        font-weight: 600;
        font-size: 60px;
        font-size: 4.17vw;
        line-height: 80px;
        line-height:  5.55vw;
        color: #FFF;
        @media (max-width: 1024px) {
            font-size: 42px;
            line-height: 56px;
        }
        @media ( max-width: 600px ) {
            margin: 0 auto 30px auto;
        }
        @media ( min-width: 1440px ) {
            font-size: 60px;
            line-height: 80px;
        }
    }
    &__content {
        width: 100%;
        background-color: #FFF;
        padding: 30px;
        @media ( max-width: 600px ) {
            padding: 15px;
        }
        &__image {
            width: 100%;
            margin-bottom: 40px;
            position: relative;
            box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.12);
            &__img {
                display: block;
                width: 100%;
            }
            &__date {
                position: absolute;
                bottom: 0;
                right: 0;
                width: 180px;
                height: 80px;
                background-color: rgba(255, 255, 255, 0.92);
                font-family: "opensans", Helvetica, Arial, Sans-Serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 80px;
                color: #5A5D64;
                text-align: center;
                @media ( max-width: 991px ) {
                    width: 90px;
                    height: 40px;
                    font-size: 12px;
                    line-height: 40px;
                }
            }
        }
        &__title {
            max-width: 1000px;
            @include h4(600);
            color: #1C1D1E;
            margin-bottom: 50px;
            @media ( max-width: 1024px ) {
                margin-bottom: 40px;
            }
        }
        &__text {
            font-size: 20px;
            line-height: 34px;
            color: #37393E;
            max-width: 1000px;
            margin-bottom: 30px;
            @media ( max-width: 600px ) {
                font-size: 16px;
                line-height: 30px;
            }
            strong {
                font-weight: 600;
            }
        }
    }
}

.new-front {
    width: 100%;
    margin: 0 auto 100px auto;
    &__inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        background-color: rgba(230, 233, 240, 0.3);
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 767px) {
            display: block;
        }
        @media ( max-width: 600px ) {

            margin-bottom: 0;
        }
    }
    &__image {
        width: 50%;
        padding-right: 30px;
        position: relative;
        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 30px;
            padding-right: 0;
        }
        &__img {
            width: 100%;
            min-height: 408px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            @media (max-width: 767px) {
                min-height: 300px;
            }
        }
        &__date {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 180px;
            height: 60px;
            background-color: rgba(255, 255, 255, 0.92);
            font-family: "opensans", Helvetica, Arial, Sans-Serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 60px;
            color: #5A5D64;
            text-align: center;
        }
    }
    &__content {
        width: 50%;
        padding-left: 30px;
        @media (max-width: 767px) {
            padding-left: 0;
            width: 100%;
        }
        &__title {
            @include h4(600);
            color: #1C1D1E;
            margin-bottom: 70px;
            @media ( max-width: 1024px ) {
                margin-bottom: 40px;
            }
        }
        &__text {
            font-size: 20px;
            line-height: 34px;
            color: #37393E;
            margin-bottom: 20px;
            @media ( max-width: 600px ) {
                font-size: 16px;
                line-height: 30px;
            }
            strong {
                font-weight: 600;
            }
        }
    }
}

.news_more {
    width: 100%;
    margin: 0 auto 100px auto;
    &__inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @media (max-width: 767px) {
            display: block;
        }
        @media ( max-width: 600px ) {
            margin-bottom: 0;
        }
    }
    &__show-all {
        align-self: flex-end;
        color: #37393E;
        cursor: pointer;
        &:before {
            background-color: #37393E;
        }
        &:hover {
            .link__text {
                color: #FFF;
            }
        }
    }
}

.news__image {
    width: 100%;
    max-width: 1440px;
    height: 412px;
    padding: 0 120px;
    margin: 0 auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    @media ( max-width: 600px ) {
        height: 250px;
    }
}

.news__navigation {
    width: 100%;
    padding: 30px 0;
    margin: 0 auto 40px auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &__inner {
        width: 100%;
        max-width: 1440px;
        padding: 0 120px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 600px ) {
            padding: 0 20px;
            margin-bottom: 0;
        }
    }
    &__prev {
        &__arrow {
            display: block;
            width: 32px;
            height: 22px;
            position: relative;
            &:after {
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                border-top: 2px solid #1C1D1E;
                border-left: 2px solid #1C1D1E;
                transform: rotate(-45deg);
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 2px;
            }
            &:before {
                content: '';
                display: block;
                width: 32px;
                height: 2px;
                background-color: #1C1D1E;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                transition: width 0.3s;
            }
            &:hover {
                &:before {
                    width: 60px;
                }
            }
        }
        &.disabled {
            opacity: 0.2;
            pointer-events: none;
        }
    }
    &__next {
        &__arrow {
            display: block;
            width: 32px;
            height: 22px;
            position: relative;
            &:after {
                content: '';
                display: block;
                width: 14px;
                height: 14px;
                border-top: 2px solid #1C1D1E;
                border-left: 2px solid #1C1D1E;
                transform: rotate(135deg);
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                right: 2px;
            }
            &:before {
                content: '';
                display: block;
                width: 32px;
                height: 2px;
                background-color: #1C1D1E;
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                transition: width 0.3s;
            }
            &:hover {
                &:before {
                    width: 60px;
                }
            }
        }
        &.disabled {
            opacity: 0.2;
            pointer-events: none;
        }
    }
}

.news__content {
    width: 100%;
    margin: 0 auto 80px auto;
    &__inner {
        width: 100%;
        max-width: 1440px;
        padding: 0 120px;
        margin: 0 auto;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 600px ) {
            padding: 0 20px;
            margin-bottom: 0;
        }
    }
    &__title {
        width: 100%;
        max-width: 900px;
        @include h2(600);
        color: #1C1D1E;
        margin-bottom: 12px;
        @media ( max-width: 600px ) {
            margin-bottom: 20px;
        }
    }
    &__date {
        font-family: "opensans", Helvetica, Arial, Sans-Serif;
        font-weight: 200;
        font-size: 24px;
        line-height: 40px;
        color: #5A5D64;
        margin-bottom: 70px;
    }
    &__intro {
        @include h4(400);
        color: #1C1D1E;
        margin-bottom: 70px;
        @media ( max-width: 1024px ) {
            margin-bottom: 40px;
        }
        strong {
            font-weight: 600;
        }
    }
    &__text {
        p {
            font-size: 18px;
            line-height: 30px;
            color: #1C1D1E;
            margin-bottom: 30px;
            strong {
                font-weight: 600;
            }
            &:last-child {
                margin-bottom: 0;
                @media ( max-width: 600px ) {
                    margin-bottom: 0;
                }
            }
            @media ( max-width: 600px ) {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 20px;
            }
        }
    }
    &__image {
        display: block;
        width: 100%;
        margin-bottom: 65px;
    }
    .text-2cols__inner {
        padding: 0;
    }
}

.quote {
    width: 100%;
    margin: 0 auto 80px 0;
    &__inner {
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        background-image: url("../icons/quote-icon.svg");
        background-repeat: no-repeat;
        background-position: top right 100px;
        @media (max-width: 1280px) {

        }
        @media ( max-width: 600px ) {

        }
    }
    &__hashtag {
        padding-left: 100px;
        font-family: "opensans", Helvetica, Arial, Sans-Serif;
        font-weight: 700;
        font-size: 25px;
        line-height: 60px;
        line-height: 4.17vw;
        color: #ED2D26;
        letter-spacing: -0.5px;
        margin-bottom: 20px;
        @media ( max-width: 767px ){
            padding-left: 0;
        }
        @media ( min-width: 1440px ) {
            line-height: 60px;
        }
    }
    &__text {
        max-width: 1000px;
        padding-left: 100px;
        // padding-right: 255px;
        @include h3(200);
        color: #1C1D1E;
        @media ( max-width: 767px ) {
            padding-left: 0;
        }
        strong {
            font-weight: 700;
        }
    }
}

.morenews {
    width: 100%;
    margin: 0 auto 20x auto;
    &__inner {
        width: 100%;
        max-width: 1440px;
        padding: 0 120px;
        margin: 0 auto;
        @media (max-width: 1280px) {
            padding: 0 40px;
        }
        @media ( max-width: 600px ) {
            padding: 0 20px;
            margin-bottom: 0;
        }
    }
    &__title {
        @include h4(600);
        color: #1C1D1E;
        margin-bottom: 45px;
        @media ( max-width: 1024px ) {
            margin-bottom: 30px;
        }
    }
    .new-front__inner {
        background-color: transparent;
        padding: 0;
        align-items: stretch;
        .new-front__content {
            width: calc(50% - 11px);
            background-color: rgba(230, 233, 240, 0.3);
            padding: 80px 40px;
            display: flex;
            flex-direction: column;
            // justify-content: space-around;
            align-items: flex-start;
            @media ( max-width: 767px ) {
                width: 100%;
                margin-bottom: 20px;
                padding: 20px;
            }
            .new-front__content__title {
                margin-bottom: 40px;
                max-width: 100%;
            }
            .new-front__content__text {
                margin-top: auto;
                max-width: 100%;
            }
        }
    }
}
