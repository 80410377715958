
@font-face {
    font-family: opensans;
    src: url('../fonts/OpenSans/OpenSans-Light.ttf');
    src: url('../fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans/OpenSans-Light.woff') format('woff'), url('../fonts/OpenSans/OpenSans-Light.ttf') format('truetype'), url('../fonts/OpenSans/OpenSans-Light.svg#svgOpenSans-Light') format('svg');
    font-weight: 200;
}

@font-face {
    font-family: opensans;
    src: url('../fonts/OpenSans/OpenSans-Regular.ttf');
    src: url('../fonts/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans/OpenSans-Regular.woff') format('woff'), url('../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype'), url('../fonts/OpenSans/OpenSans-Regular.svg#svgOpenSans-Regular') format('svg');
    font-weight: 400;
}

@font-face {
    font-family: opensans;
    src: url('../fonts/OpenSans/OpenSans-Semibold.ttf');
    src: url('../fonts/OpenSans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans/OpenSans-Semibold.woff') format('woff'), url('../fonts/OpenSans/OpenSans-Semibold.ttf') format('truetype'), url('../fonts/OpenSans/OpenSans-Semibold.svg#svgOpenSans-Regular') format('svg');
    font-weight: 600;
}

@font-face {
    font-family: opensans;
    src: url('../fonts/OpenSans/OpenSans-Bold.ttf');
    src: url('../fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/OpenSans/OpenSans-Bold.woff') format('woff'), url('../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype'), url('../fonts/OpenSans/OpenSans-Bold.svg#svgOpenSans-Regular') format('svg');
    font-weight: 700;
}
