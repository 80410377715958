.faq {
  width: 100%;
  margin: 0 auto 120px auto;
  @media (max-width: 768px) {
    margin: 0 auto 60px auto;
  }
  @media (max-width: 600px) {
    margin: 0 auto;
  }
  &__inner {
    width: 100%;
    max-width: 880px;
    padding: 0 40px;
    margin: 0 auto;
    @media (max-width: 1280px) {
      padding: 0 40px;
    }
    @media (max-width: 767px) {
      flex-direction: column;
    }
    @media (max-width: 600px) {
      padding: 0 20px;
      margin-bottom: 0;
    }
  }
  .section-title {
    text-align: center;
  }
  &__accordion {
    &__item {
      position: relative;
      border-bottom: 1px solid #979797;
      transition: all 0.3s;
      &__title {
        padding: 46px 112px 24px 40px;
        font-size: 20px;
        line-height: 34px;
        color: #37393e;
        @media (max-width: 767px) {
          padding: 46px 112px 24px 40px;
        }

        cursor: pointer;
        transition: all 0.3s;
      }
      &__content {
        padding: 0 112px 0 40px;
        height: 0;
        overflow: hidden;

        font-size: 20px;
        line-height: 34px;

        transition: all 0.3s;
      }
      &.open {
        background-color: rgba(#d8d8d8, 0.18);
        .faq__accordion__item__title {
          font-weight: 600;
        }
        .faq__accordion__item__content {
          padding: 24px 112px 52px 40px;
          height: auto;
          @media (max-width: 767px) {
            padding: 24px 40px 52px 40px;
          }
          a {
            word-wrap: anywhere;
          }
        }
        &:after {
          transform: rotate(180deg);
          bottom: 52px;
        }
      }
      &::after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        bottom: 24px;
        right: 10px;
        background-image: url("../icons/down-black-icon.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 16px;
        pointer-events: none;

        transition: all 0.3s;
      }
    }
  }
}
