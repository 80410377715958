.claim {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    &__bg {
        width: 100%;
        height: 500px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @media ( max-width: 600px ) {
            height: 300px;
        }
    }
    &__inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        max-width: 1440px;
        padding: 104px 120px 0 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 1280px) {
            padding: 104px 40px 0 40px;
        }
        @media (max-width: 600px) {
            padding: 104px 20px 0 20px;
        }
    }
    &__title {
        @include h2(400);
        text-align: center;
        margin: 0;
        strong {
            display: block;
            font-weight: 600;
        }
    }
}